import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

const CookieConsent = props => {
    const { t } = useTranslation()

    return <div className='cookie-container'>
        <p>{t('CookieConsentText')} <Link to='/cookie-consent' className='cookies-learn-more'>{t('CookieConsentLearnMore')}</Link></p>
        <div className='cookie-btns-container'>
            <button className='cookie-accept-btn' onClick={props.onClick}>{t('CookieBtn2')}</button>
        </div>
    </div>
}

export default CookieConsent;
